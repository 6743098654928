<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import { debounce } from 'vue-debounce'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { VueGoodTable } from 'vue-good-table'
import { SweetModal } from 'sweet-modal-vue'
import VuePdfApp from 'vue-pdf-app'
import PaginationCustom from '@/components/table/Pagination.vue'
import appData from '@/data'

import 'vue-pdf-app/dist/icons/main.css'
import ProyekDetail from '@/components/oss/ProyekDetail.vue'

export default {
   name: 'IzinRba',
   components: {
      BCardCode,
      VueGoodTable,
      PaginationCustom,
      SweetModal,
      VuePdfApp,
      ProyekDetail,
   },
   data() {
      return {
         pdf: '',
         loading: true,
         loadingPdf: false,
         inputSearch: true,
         inputPeriode: '',
         serverParams: {
            columnFilters: {},
            sort: {
               field: '',
               type: '',
            },
            search: '',
            page: 1,
            perPage: 10,
         },
         pageLength: 10,
         pageOptions: ['10', '25', '50', '100'],
         searchTerm: '',
         columns: [
            {
               label: 'Nama Dokumen',
               field: 'dokumen',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'NIB / Nama',
               field: 'nib',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'KBLI / Sektor / Resiko',
               field: 'usaha',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Nama Usaha/ Alamat Usaha',
               field: 'alamat',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Kewenangan/Status',
               field: 'status',
               sortable: false,
               thClass: 'text-center align-middle',
            },
            {
               label: 'Action',
               field: 'action',
               sortable: false,
               thClass: 'text-center align-middle',
            },
         ],
         filter: {
            kecamatan: null,
            periode: null,
            tahun: null,
            bulan: null,
            jenis: null,
            kewenangan: null,
            skala: null,
            resiko: null,
            status: null,
            sektor: null,
         },
         options: {
            jenis: appData.filterOss.jenisIzinRba,
            kewenangan: appData.filterOss.kewenanganIzinRba,
            kecamatan: appData.filterOss.kecamatanOpt,
            skala: appData.filterOss.skalaUsaha,
            resiko: appData.filterOss.resiko,
            periode: appData.filterOss.periodeTerbit,
            status: appData.filterOss.statusIzin,
         },
      }
   },
   computed: {
      ...mapState({
         myData: state => state.OssIzinRba.items,
         myCount: state => state.OssIzinRba.countAll,
      }),
      jenisPerusahaan() {
         const data = appData.jenisPerusahaan
         return status => (data[status] ? data[status] : status)
      },
      skalaUsaha() {
         const data = appData.skalaUsaha
         return status => (data[status] ? data[status] : status)
      },
      statusPM() {
         const data = appData.statusPm
         return status => (data[status] ? data[status] : status)
      },
      statusResiko() {
         const data = appData.statusResiko
         return status => (data[status] ? data[status] : status)
      },
      statusNIB() {
         const data = appData.statusNib
         return status => (data[status] ? data[status] : status)
      },
      statusBadanHukum() {
         const data = appData.statusBadanHukum
         return status => (data[status] ? data[status] : status)
      },
      statusIzin() {
         const data = appData.statusIzin
         return status => (data[status] ? data[status] : status)
      },
      kewenanganIzin() {
         const data = appData.kewenanganIzin
         return kewenangan => (data[kewenangan] ? data[kewenangan] : kewenangan)
      },
      kewenanganColor() {
         return kewenangan => (kewenangan === '02'
            ? 'text-danger font-weight-bold'
            : 'text-dark font-italic')
      },
      sektorCode() {
         const data = appData.sektor
         return status => (data[status] ? data[status] : status)
      },
      statusNIBVariant() {
         const data = {
            /* eslint-disable key-spacing */
            '01': 'success',
            '06': 'danger',
            /* eslint-enable key-spacing */
         }
         return status => (data[status] ? data[status] : 'primary')
      },
   },
   watch: {
      loadingPdf(val) {
         if (val) {
            this.$swal({ text: 'Loading', allowOutsideClick: false })
            this.$swal.showLoading()
         } else {
            this.$swal.close()
         }
      },
   },
   mounted() {
      this.loadItems()
   },
   methods: {
      ...mapActions({
         getData: 'OssIzinRba/getData',
         cetakIzin: 'OssIzinRba/cetakIzin',
         cetakNib: 'OssNib/cetakNib',
      }),
      ...mapMutations({
         setIdProyek: 'OssBidangUsaha/SET_ID_PROYEK',
      }),
      onCetakIzin(id) {
         this.loadingPdf = true
         this.cetakIzin(id).then(
            res => {
               const blob = new Blob([res.data])
               const objectUrl = URL.createObjectURL(blob)
               this.pdf = objectUrl

               // URL.revokeObjectURL(objectURL)
               this.$refs.modalPdf.open()
               // this.$bvModal.show('modal-cetak-izin-lama')
               this.loadingPdf = false
            },
            () => {
               this.loadingPdf = false
            },
         )
      },
      onCetakNib(nib) {
         this.loadingPdf = true
         this.cetakNib(nib).then(
            res => {
               const blob = new Blob([res.data])
               this.pdf = URL.createObjectURL(blob)
               // URL.revokeObjectURL(objectURL)
               this.$refs.modalPdf.open()
               this.loadingPdf = false
            },
            () => {
               this.loadingPdf = false
            },
         )
      },
      onDetailProyek(idProyek) {
         this.setIdProyek(idProyek)
         this.$bvModal.show('modal-detail')
      },

      // VUE GOOD TABLE
      async loadItems() {
         this.loading = true
         await this.getData(this.serverParams)
      },
      updateParams(newProps) {
         // eslint-disable-next-line prefer-object-spread
         this.serverParams = Object.assign({}, this.serverParams, newProps)
      },
      onPageChangePagination(page) {
         this.updateParams({ page })
         this.loadItems()
      },
      onPerPageChangePagination(perPage) {
         this.updateParams({ perPage, page: 1 })
         this.loadItems()
      },
      onColumnFilter(params) {
         this.updateParams(params)
         this.loadItems()
      },
      onSearch: debounce(function search(params) {
         this.serverParams.search = params
         this.serverParams.page = 1
         this.loadItems()
      }, 500),
      onFilterOpen() {
         this.inputSearch = false
         this.searchTerm = ''
         console.log('open')
      },
      onFilterClose() {
         this.inputSearch = true
         console.log('close')
      },
      onPeriodeChange(val) {
         console.log(val)
         this.inputPeriode = val
         this.filter.tahun = null
         this.filter.bulan = null
      },
      onFilter() {
         setTimeout(() => {
            this.updateParams({ filter: this.filter })
            this.loadItems()
         }, 100)
      },
      onSubmit() {
         return null
      },
   },
}
</script>

<template>
   <div>
      <b-card-code title="Data Perizinan RBA">
         <!-- search input -->
         <b-row>
            <!-- TOOLBAR -->
            <b-col cols="12" md="6">
               <b-form @submit.stop.prevent>
                  <div class="d-flex align-items-center justify-content-end">
                     <b-form-input
                        v-model="searchTerm"
                        class="d-inline-block mb-1"
                        placeholder="Search..."
                        autocomplete="off"
                        type="text"
                        aria-label="Search"
                        @input="onSearch"
                     />
                  </div>
               </b-form>
            </b-col>
            <b-col cols="12" md="6">
               <div class="d-flex justify-content-end">
                  <b-button-group class="mb-1">
                     <b-button
                        v-b-toggle.collapse-filter
                        variant="outline-secondary"
                        size="sm"
                     >
                        <feather-icon icon="FilterIcon" />
                     </b-button>
                     <b-button
                        variant="outline-secondary"
                        size="sm"
                        @click="loadItems()"
                     >
                        <feather-icon icon="RefreshCwIcon" />
                     </b-button>
                     <b-dropdown
                        variant="outline-secondary"
                        right
                        text="Menu"
                        size="sm"
                     >
                        <b-dropdown-item @click="onFilter()">
                           Filter
                        </b-dropdown-item>
                        <b-dropdown-item @click="loadItems()">
                           Refresh
                        </b-dropdown-item>
                     </b-dropdown>
                  </b-button-group>
               </div>
            </b-col>

            <b-col>
               <b-collapse
                  id="collapse-filter"
                  class="mt-2"
                  @onLeave="onFilterOpen"
                  @onEnter="onFilterClose"
                  @shown="onFilterOpen"
                  @hide="onFilterClose"
               >
                  <b-card class="border">
                     <b-row>
                        <b-col md="3">
                           <b-form-group label="Skala Usaha" label-for="skala">
                              <b-form-select
                                 v-model="filter.skala"
                                 :options="options.skala"
                                 @input="onFilter"
                              />
                           </b-form-group>
                        </b-col>
                        <b-col md="3">
                           <b-form-group label="Resiko" label-for="resiko">
                              <b-form-select
                                 v-model="filter.resiko"
                                 :options="options.resiko"
                                 @input="onFilter"
                              />
                           </b-form-group>
                        </b-col>
                        <b-col md="3">
                           <b-form-group label="Jenis" label-for="jenis">
                              <b-form-select
                                 v-model="filter.jenis"
                                 :options="options.jenis"
                                 @input="onFilter"
                              />
                           </b-form-group>
                        </b-col>
                        <b-col md="3">
                           <b-form-group
                              label="Kewenangan"
                              label-for="kewenangan"
                           >
                              <b-form-select
                                 v-model="filter.kewenangan"
                                 :options="options.kewenangan"
                                 @input="onFilter"
                              />
                           </b-form-group>
                        </b-col>
                        <b-col md="3">
                           <b-form-group label="Status Izin" label-for="status">
                              <b-form-select
                                 v-model="filter.status"
                                 :options="options.status"
                                 @input="onFilter"
                              />
                           </b-form-group>
                        </b-col>
                        <b-col md="3">
                           <b-form-group label="Sektor" label-for="sektor">
                              <b-form-select
                                 v-model="filter.sektor"
                                 :options="options.status"
                                 @input="onFilter"
                              />
                           </b-form-group>
                        </b-col>
                        <b-col md="3">
                           <b-form-group
                              label="Kecamatan"
                              label-for="kecamatan"
                           >
                              <b-form-select
                                 v-model="filter.kecamatan"
                                 :options="options.kecamatan"
                                 @input="onFilter"
                              />
                           </b-form-group>
                        </b-col>
                        <b-col md="3">
                           <b-form-group
                              label="Periode Terbit"
                              label-for="periode"
                           >
                              <b-form-select
                                 v-model="filter.periode"
                                 :options="options.periode"
                                 @change="onPeriodeChange"
                              />
                           </b-form-group>
                        </b-col>
                        <b-col v-if="inputPeriode === 'tahun'" md="3">
                           <b-form-group label="Tahun Terbit" label-for="tahun">
                              <datepicker
                                 v-model="filter.tahun"
                                 :minimum-view="'year'"
                                 :maximum-view="'year'"
                                 format="yyyy"
                                 input-class="form-control"
                                 @input="onFilter"
                              />
                           </b-form-group>
                        </b-col>
                        <b-col v-if="inputPeriode === 'bulan'" md="3">
                           <b-form-group label="Bulan Terbit" label-for="tahun">
                              <datepicker
                                 v-model="filter.bulan"
                                 :minimum-view="'year'"
                                 :maximum-view="'year'"
                                 format="yyyy"
                                 input-class="form-control"
                                 @input="onFilter"
                              />
                           </b-form-group>
                        </b-col>
                     </b-row>
                  </b-card>
               </b-collapse>
            </b-col>
         </b-row>

         <!-- table -->
         <vue-good-table
            ref="goodTable"
            mode="remote"
            :columns="columns"
            :rows="myData"
            :total-rows="myCount"
            :is-loading.sync="loading"
            :line-numbers="true"
            :pagination-options="{
               enabled: true,
               mode: 'pages',
            }"
            :search-options="{
               enabled: true,
               externalQuery: searchTerm,
            }"
            @on-column-filter="onColumnFilter"
            @on-search="onSearch"
         >
            <template slot="table-row" slot-scope="props">
               <!-- Column: Name -->
               <span v-if="props.column.field == 'dokumen'">
                  <b>{{ props.row.nama_dokumen }}</b>
                  <hr>
                  <small>Terbit :
                     {{
                        props.row.tgl_terbit_dokumen | moment("DD-MM-YYYY")
                     }}</small>
               </span>

               <span v-if="props.column.field == 'nib'">
                  <b>{{ props.row.nama_perusahaan }}</b>
                  <br>
                  <hr>
                  <b class="text-primary">{{ props.row.nib }}</b>
                  <br>
                  <small>{{
                     jenisPerusahaan(props.row.jenis_perusahaan)
                  }}</small>
                  <hr>
                  <small>{{
                     skalaUsaha(props.row.kd_skala_usaha_final)
                  }}</small>
               </span>

               <span v-if="props.column.field == 'alamat'">
                  <small>
                     <b>{{ props.row.nama_proyek }}</b>
                  </small>
                  <hr>
                  <small>{{ props.row.alamat_usaha }}</small>
                  <br>
                  <small class="font-weight-bold font-italic">
                     Kel/Desa:
                     <b>{{ props.row.kelurahan }}</b>
                  </small>
                  <br>
                  <small class="font-weight-bold font-italic">
                     Kec:
                     <b>{{ props.row.kecamatan }}</b>
                  </small>
               </span>

               <span v-if="props.column.field == 'status'">
                  <center>
                     <small :class="kewenanganColor(props.row.kewenangan)">{{
                        kewenanganIzin(props.row.kewenangan)
                     }}</small>
                  </center>
                  <hr>
                  <center>
                     <b-badge variant="secondary" class="mt-50">
                        <small>{{ statusIzin(props.row.status_respon) }}</small>
                     </b-badge>
                  </center>
               </span>

               <span v-if="props.column.field == 'usaha'">
                  <b>KBLI : {{ props.row.kbli }}</b>
                  <br>
                  <small>{{ props.row.nama_kbli }}</small>
                  <hr>
                  <small>
                     Sektor :
                     <b class="text-danger">{{
                        sektorCode(props.row.sektor)
                     }}</b>
                  </small>
                  <hr>
                  <small>
                     Resiko :
                     <b class="text-danger">{{
                        statusResiko(props.row.kd_resiko)
                     }}</b>
                  </small>
                  <hr>
                  <small>
                     Modal :
                     <b class="text-danger">{{
                        props.row.jumlah_investasi | formatNumber
                     }}</b>
                  </small>
                  <hr>
                  <small>
                     Skala :
                     <b class="text-danger">{{
                        skalaUsaha(props.row.kd_skala_usaha)
                     }}</b>
                  </small>
                  <br>
                  <small>{{ props.row.id_proyek }}</small>
               </span>

               <span v-if="props.column.field == 'action'">
                  <b-button
                     size="sm"
                     block
                     variant="outline-dark"
                     @click="onDetailProyek(props.row.id_proyek)"
                  >
                     <small>Detail</small>
                  </b-button>
                  <b-button
                     v-if="props.row.s3_path"
                     v-b-tooltip.hover.top="'Cetak Dokumen'"
                     size="sm"
                     block
                     variant="primary"
                     @click="onCetakIzin(props.row.id_permohonan_izin)"
                  >
                     <feather-icon icon="FileTextIcon" class="mr-50" />
                     <small>Dokumen</small>
                  </b-button>
                  <b-button
                     v-b-tooltip.hover.top="'Cetak NIB'"
                     size="sm"
                     block
                     variant="primary"
                     @click="onCetakNib(props.row.nib)"
                  >
                     <feather-icon icon="FileTextIcon" class="mr-50" />
                     <small>NIB</small>
                  </b-button>
               </span>

               <!-- Column: Common -->
               <!-- <span v-else>{{ props.formattedRow[props.column.field] }}</span> -->
               <!-- <span v-else>{{ props.formattedRow[props.column.field] }}</span> -->
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
               <pagination-custom
                  :total="props.total"
                  :page-length="pageLength"
                  :page-options="pageOptions"
                  :page-changed="props.pageChanged"
                  :per-page-changed="props.perPageChanged"
                  @update:page="onPageChangePagination"
                  @update:perpage="onPerPageChangePagination"
               />
            </template>
         </vue-good-table>
      </b-card-code>

      <b-modal
         id="modal-detail"
         scrollable
         size="lg"
         centered
         hide-footer
         title="Bidang Usaha Detail"
      >
         <ProyekDetail ref="proyekDetail" />
      </b-modal>

      <sweet-modal
         ref="modalPdf"
         modal-theme="light"
         overlay-theme="dark"
         width="80%"
      >
         <!-- <pdf :src="pdf" /> -->
         <div style="height: 70vh">
            <vue-pdf-app :pdf="pdf" />
         </div>
      </sweet-modal>
   </div>
</template>

<style scoped>
.borderless > tr > td {
   border: none;
   padding: 0em;
}
table > tbody > tr > td > span > hr {
   margin-top: 0rem;
   margin-bottom: 0rem;
}

.nowrap {
   white-space: nowrap;
}
</style>
